import { Directive } from '@angular/core';
import { Observable } from 'rxjs';
import { SignInState } from 'src/app/core/providers/BackendAuthClient';

// Class used as a "narrowing" interface
@Directive()
export abstract class AuthProvider {
  providerName$ = '';

  abstract get checkAuth$(): Observable<boolean>;
  abstract get isAuthenticated(): boolean;
  abstract get isAuthenticated$(): Observable<boolean>;
  abstract get nibioToken(): string;

  abstract checkAuthAndSignOutIfNotAuthenticated(): void;
  abstract renewToken(): void;
  abstract signIn(returnUrl: string): void;
  abstract signInCallback(code: string, state: SignInState): void;
  abstract signOut(returnUrl?: string): void;
  abstract signOutCallback(state: string): void;
}
